export const STOREUSER ="STOREUSER"
export const REMOVEUSER ="REMOVEUSER"
export const GETAGENCY ="GETAGENCY"
export const ADDAGENCY ="ADDAGENCY"
export const EDITAGENCY ="EDITAGENCY"
export const DELETEAGENCY ="DELETEAGENCY"
export const GETWAREHOUSE ="GETWAREHOUSE"
export const ADDWAREHOUSE="ADDWAREHOUSE"
export const EDITWAREHOUSE="EDITWAREHOUSE"
export const DELETEWAREHOUSE="DELETEWAREHOUSE"
export const GETAGENCYCOUNT="GETAGENCYCOUNT"
export const GETWAREHOUSECOUNT="GETWAREHOUSECOUNT"
export const ADDDOCKET ="ADDDOCKET"
export const EDITDOCKET ="EDITDOCKET"
export const GETDOCKET ="GETDOCKET"
export const GETDOCKETCOUNT="GETDOCKETCOUNT"
export const GETWAREHOUSEPENDING="GETWAREHOUSEPENDING"
export const GETZONE="GETZONE"
export const GETSTATE="GETSTATE"
export const GETDISTRICT="GETDISTRICT" 
export const GETCONSIGNEE="GETCONSIGNEE"
export const GETCSVLIST="GETCSVLIST"
export const ADDCONSIGNEE="ADDCONSIGNEE"
export const EDITCONSIGNEE="EDITCONSIGNEE"
export const GETCONSIGNEECOUNT="GETCONSIGNEECOUNT"
export const GETCONSIGNOR="GETCONSIGNOR"
export const EDITCONSIGNOR="EDITCONSIGNOR"
export const ADDCONSIGNOR="ADDCONSIGNOR"
export const GETUNTRACKEDDOCKET="GETUNTRACKEDDOCKET"
export const GETIMAGE="GETIMAGE"
export const GETSEARCHWH="GETSEARCHWH"
export const GETPENDINGDOCKETCOUNT="GETPENDINGDOCKETCOUNT"
export const GETPENDINGDOCKET="GETPENDINGDOCKET"
export const EDITPENDINGDOCKET="EDITPENDINGDOCKET"