import { STOREUSER,REMOVEUSER} from "../actions/actionType";
const initialState = {
 user:{valid:false}
};
export default function authReducer(state = initialState, action) {
    switch(action.type) {
      case STOREUSER:{
        return { ...state,user:action.payload};
      }
      case REMOVEUSER:{
        return { ...state,user:action.payload};
      }
      default:
        return state;
    }
  }