import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import React, { Suspense, lazy, Fragment } from "react";

import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";

const Dashboards = lazy(() => import("../../DemoPages/Dashboards"));
const Components = lazy(() => import("../../DemoPages/Components"));
const Signin = lazy(() => import("../../DemoPages/Dashboards/Signin"));
const ForgotPassword = lazy(() =>
  import("../../DemoPages/Dashboards/ForgotPassword")
);
const TrackDocket = lazy(() =>
  import("../../DemoPages/Dashboards/TrackDocket")
);
const Tables = lazy(() => import("../../DemoPages/Tables"));
const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = useSelector((state) => state.authReducer.user);
  let Authorized = auth && auth.valid;

  return (
    <Route
      {...rest}
      render={(props) =>
        Authorized ? <Component {...props} /> : <Redirect to="/signin" />
      }
    />
  );
};
const AppMain = () => {
  return (
    <Fragment>
      {/* Components */}

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-5">Please wait while we load</h6>
            </div>
          </div>
        }
      >
        <PrivateRoute path="/components" component={Components} />
      </Suspense>

      {/* Forms */}

      {/* Charts */}

      {/* Tables */}

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-5">Please wait while we load</h6>
            </div>
          </div>
        }
      >
        <PrivateRoute path="/tables" component={Tables} />
      </Suspense>

      {/* Elements */}

      {/* Dashboard Widgets */}

      {/* Dashboards */}

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-3">Please wait while we load</h6>
            </div>
          </div>
        }
      >
        <PrivateRoute path="/dashboards" component={Dashboards} />
      </Suspense>
      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-3">Please wait while we load</h6>
            </div>
          </div>
        }
      >
        <Route path="/signin" component={Signin} />
      </Suspense>
      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-3">Please wait while we load</h6>
            </div>
          </div>
        }
      >
        <Route path="/forgot-password" component={ForgotPassword} />
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-3">Please wait while we load</h6>
            </div>
          </div>
        }
      >
        <Route path="/track" component={TrackDocket} />
      </Suspense>

      <Route
        exact
        path="/"
        render={() => <Redirect to="/dashboards/basic" />}
      />

      <ToastContainer />
    </Fragment>
  );
};

export default AppMain;
