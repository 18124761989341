import {
  GETWAREHOUSE,
  GETAGENCY,
  GETAGENCYCOUNT,
  EDITAGENCY,
  GETWAREHOUSECOUNT,
  EDITWAREHOUSE,
  GETDOCKET,
  GETDOCKETCOUNT,
  GETWAREHOUSEPENDING,
  GETZONE,
  GETSTATE,
  GETDISTRICT,
  GETCONSIGNEE,
  GETCSVLIST,
  EDITCONSIGNEE,
  GETCONSIGNEECOUNT,
  GETCONSIGNOR,
  GETSEARCHWH,
  EDITCONSIGNOR,
  GETUNTRACKEDDOCKET,
  GETIMAGE,
  GETPENDINGDOCKETCOUNT,
  GETPENDINGDOCKET,
} from "../actions/actionType";
const initialState = {
  agencylist: [],
  agencycount: 0,
  warehouselist: [],
  warehousecount:0,
  docketlist: [],
  docketcount:0,
  list:[],
  zonelist:[],
  statelist:[],
  searchwarehouselist:[],
  districtlist:[],
  consigneelist:[],
  downloadlist:[{"id":66,"DocketNumber":"jef889","DocketName":"","description":"","pieces":99,"weight":99,"source":"verna warehouse"}],
  consigneecount:0,
  consignorlist:[],
  untrackeddocketlist:[],
  docketimage:[],
  pendingdocketcount:0,
  pendingdocketlist:[],
};
export default function logisticsReducer(state = initialState, action) {
  switch (action.type) {
    case GETAGENCYCOUNT: {
      return { ...state, agencycount: action.payload };
    }
    case GETAGENCY: {
      let agency=[]
      action.payload.map((i) => {
        agency.push({...i, label: i.Ajencyname, value: i.id});
      });
      return { ...state, agencylist: agency };
    }
    case EDITAGENCY: {
      const { agencylist } = state;
      let updatedlist = [];
      let { id } = action.payload || {};
      agencylist.map((item) => {
        if (item.id == id) {
          updatedlist.push(action.payload);
        } else {
          updatedlist.push(item);
        }
      });
      return { ...state, agencylist: updatedlist };
    }
    case GETWAREHOUSECOUNT: {
        return { ...state, warehousecount: action.payload };
      }
      case GETWAREHOUSE: {
        let warehouse=[]
        action.payload.map((i)=>{
          warehouse.push({...i,label:i.name, value: i.id})
        })
        return { ...state, warehouselist: warehouse };
      }
      case GETWAREHOUSEPENDING: {
        return { ...state, list: action.payload };
      }
      case EDITWAREHOUSE: {
        const { warehouselist } = state;
        let updatedlist = [];
        let { id } = action.payload || {};
        warehouselist.map((item) => {
          if (item.id == id) {
            updatedlist.push({...item,...action.payload});
          } else {
            updatedlist.push(item);
          }
        });
        return { ...state, warehouselist: updatedlist };
      }
      case GETDOCKET: {
        return { ...state, docketlist: action.payload };
      }
      case GETDOCKETCOUNT: {
        return { ...state, docketcount: action.payload };
      }case GETZONE: {
        let zone=[]
        let state=[]
      action.payload.map((i) => {
        zone.push({...i, label: i.ZoneName, value: i.id});
      });
      state.push({label:'',value:''});
        return { ...state, zonelist: zone,statelist:state,districtlist:state };
      }case GETSTATE:{
        let statelst=[]
        action.payload.map((i)=>{
          statelst.push({...i,label:i.StateName, value: i.id})
        })
        return { ...state, statelist: statelst,districtlist:[] };
      } case GETDISTRICT:{
        let district=[]
        action.payload.map((i)=>{
          district.push({...i, label:i.DistrictName, value:i.id})
        })
        return {...state,districtlist:district};
      }case GETCONSIGNEE:{
        let consignee=[]
        action.payload.map((i)=>{
          consignee.push({...i, label:i.name, value:i.id})
        })
        return {...state,consigneelist:consignee};
      }case GETCSVLIST: {
        return { ...state, downloadlist: action.payload };
      }case EDITCONSIGNEE: {
        const { consigneelist } = state;
        let updatedlist = [];
        let { id } = action.payload || {};
        consigneelist.map((item) => {
          if (item.id == id) {
            updatedlist.push({...item,...action.payload});
          } else {
            updatedlist.push(item);
          }
        });
        return { ...state, consigneelist: updatedlist };
      }
      case GETCONSIGNEECOUNT:{
        return { ...state, consigneecount: action.payload };
      }case GETCONSIGNOR:{
        let consignor=[];
        action.payload.map((i)=>{
          consignor.push({...i, label:i.name, value:i.id})
        })
        return { ...state, consignorlist: consignor };
      }case EDITCONSIGNOR: {
        const { consignorlist } = state;
        let updatedlist = [];
        let { id } = action.payload || {};
        consignorlist.map((item) => {
          if (item.id == id) {
            updatedlist.push({...item,...action.payload});
          } else {
            updatedlist.push(item);
          }
        });
        return { ...state, consignorlist: updatedlist };
      }case GETUNTRACKEDDOCKET:{
        return { ...state, untrackeddocketlist: action.payload };
      }case GETIMAGE: {
        return { ...state, docketimage: action.payload };
      }
      case GETSEARCHWH:{
        return { ...state, searchwarehouselist: action.payload };
      }

      case GETPENDINGDOCKETCOUNT: {
        return { ...state, pendingdocketcount: action.payload };
      }case GETPENDINGDOCKET:{
        return { ...state, pendingdocketlist: action.payload };
      }
 
    default:
      return state;
  }
}
